<template>
  <div id="About-Us">
    <div id="Software-License">
      <a-button @click="softwareLicenseShow = true">
        <router-link :to="{}">《软件使用许可协议》</router-link>
      </a-button>
      <a-modal
        title="《软件使用许可协议》"
        :visible="softwareLicenseShow"
        @cancel="softwareLicenseShow = false"
      >
        <template slot="footer">
          <a-button @click="softwareLicenseShow = false">关闭</a-button>
        </template>
        <div>
          {{ content }}
        </div>
      </a-modal>
    </div>
    <div id="Call-Center">
      <div class="top">
        <div class="bottom code">
          <div class="image-transform">
            <img :src="`${$public}/images/service_wx.png`" alt="" />
          </div>
          <div>
            <p>长按图片添加客服人员微信</p>
          </div>
          <a-button>
            <router-link :to="{ name: 'Online-Consultant' }">
              在线联系客服
            </router-link>
          </a-button>
        </div>
      </div>
    </div>
    <div id="Info">
      <!-- <p>桂ICP备20002856号</p> -->
      <p>HM pool V2.0.0</p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Modal } from "ant-design-vue";
Vue.use(Modal);

export default {
  props: {},
  data() {
    return {
      softwareLicenseShow: false,
      content: "",
    };
  },
  methods: {},
  created() {
    this.$axios.get("./text/Software-License.txt").then((res) => {
      // console.log(res);
      this.content = res;
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
#About-Us {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
  align-items: space-between;
  padding: 1em;

  > div {
    //: 1.2em;
    width: 100%;
  }

  * {
    text-align: center;
  }

  > #Software-License {
  }

  #Call-Center {
    // position: fixed !important;
    // top: 50% !important;
    // left: 50% !important;
    // transform: translate(-50%, -50%);
    // -ms-transform: translate(-50%, -50%); /* IE 9 */
    // -moz-transform: translate(-50%, -50%); /* Firefox */
    // -webkit-transform: translate(-50%, -50%); /* Safari 和 Chrome */
    // -o-transform: translate(-50%, -50%); /* Opera */
    width: 90% !important;
    text-align: center;
    //: 0.3rem;
    line-height: 2em;
    min-height: unset !important;
    margin: 1em auto;

    p {
      color: #acacac;
    }

    > .top {
      margin-bottom: 0.4rem;

      > .bottom {
        > .transform {
          width: 100%;
          height: 0;
          padding-bottom: 100%;
          position: relative;
          margin-bottom: 0.2rem;
        }
      }
    }
  }

  #Info {
    p {
      color: #acacac;
    }
  }
}
</style>
